<template>
    <div class="list">
      <!--  供应商生命周期调整列表 -->
      <list-search :childData="childData" @onSearch="onSearch" @clearSearch="clearSearch"></list-search>
      <global-table :tableField="tableField"  :tableData="tableData" :paginationData="paginationData"  @pageChange="pageChange"></global-table>
    </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/suplifecycleadjustList'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'SuplifecycleadjustList',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 340) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      tableData: tableData,
      searchdata: SearchData,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      pageNum: 1
    }
  },
  mounted () {
    // 获取考核周期
    var newOption = []
    request('/api/supplier/supplierLevel/getAssessCycle', 'get').then((res) => {
      this.newOption = res.data
      res.data.forEach(item => {
        newOption.push({
          label: item,
          value: item
        })
      })
      console.log('000', this.newOption)
      this.childData.searchList[0].option = newOption
    })
  },
  created () {
    this.SuplifecycleadjustList()
  },
  methods: {
    SuplifecycleadjustList () {
      var pageSize = parseInt((document.documentElement.clientHeight - 340) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      let fbKsTime = ''
      let fbJsTime = ''
      if (this.childData.searchData.applyDate) {
        fbKsTime = dayjs(this.childData.searchData.applyDate[0]).format('YYYY-MM-DD HH:mm:ss')
        fbJsTime = dayjs(this.childData.searchData.applyDate[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      let bjKsTime = ''
      let bjJsTime = ''
      if (this.childData.searchData.adjustDate) {
        bjKsTime = dayjs(this.childData.searchData.adjustDate[0]).format('YYYY-MM-DD HH:mm:ss')
        bjJsTime = dayjs(this.childData.searchData.adjustDate[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      const obj = {
        type: this.searchdata.type,
        assessCycle: this.searchdata.assessCycle,
        providerInfo: this.searchdata.providerInfo,
        adjustType: this.searchdata.adjustType,
        materialType: this.searchdata.materialType,
        applyBeginDate: fbKsTime,
        applyEndDate: fbJsTime,
        adjustBeginDate: bjKsTime,
        adjustEndDate: bjJsTime
      }
      request('/api/supplier/supplierLevel/getChangeDataList?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize, 'get', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.SuplifecycleadjustList()
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.SuplifecycleadjustList()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.SuplifecycleadjustList()
    }
  }

}

</script>
<style scoped lang="scss">
 .list{
  :deep(.tableList){
    padding: 0 30px !important;
    margin-top: 20px !important;
  }
 }
</style>
