
export var SearchList = [
  {
    labe: '考核周期',
    code: 'assessCycle',
    type: 'select',
    option: []
  },
  { labe: '供应商', code: 'providerInfo', type: 'input' },
  {
    labe: '物料品类',
    code: 'materialType',
    type: 'select',
    option: [
      { label: '主板', value: '主板' },
      { label: 'CPU', value: 'CPU' },
      { label: '硬盘', value: '硬盘' },
      { label: '内存', value: '内存' },
      { label: '板卡', value: '板卡' },
      { label: '电源', value: '电源' },
      { label: '风扇', value: '风扇' },
      { label: '散热器', value: '散热器' },
      { label: '线缆', value: '线缆' },
      { label: '交换机', value: '交换机' },
      { label: '磁盘阵列', value: '磁盘阵列' },
      { label: 'OEM安全产品', value: 'OEM安全产品' },
      { label: '商品', value: '商品' },
      { label: '包材', value: '包材' },
      { label: '结构件', value: '结构件' },
      { label: '光模块', value: '光模块' },
      { label: '软件', value: '软件' },
      { label: '固定资产', value: '固定资产' },
      { label: '外设', value: '外设' },
      { label: '云计算集成外包', value: '云计算集成外包' },
      { label: '外协', value: '外协' },
      { label: '元器件', value: '元器件' },
      { label: '其它', value: '其它' }
    ]
  },
  {
    labe: '调整类型',
    code: 'adjustType',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '升级', value: '1' },
      { label: '降级', value: '2' }
    ]
  },
  {
    labe: '申请日期',
    code: 'applyDate',
    type: 'daterange'
  },
  {
    labe: '变更日期',
    code: 'adjustDate',
    type: 'daterange'
  }

]
export var SearchData = {
  assessCycle: '',
  providerInfo: '',
  materialType: '',
  adjustType: '',
  applyBeginDate: '',
  applyEndDate: '',
  adjustBeginDate: '',
  adjustEndDate: ''
}
export var tableField = [
  { label: '考核周期', code: 'assessCycle', type: 'input', width: '80' },
  { label: '供应商编码', code: 'providerCode', type: 'input', width: '' },
  { label: '供应商名称', code: 'providerName', type: 'input', width: '130' },
  { label: '物料品类', code: 'materialType', type: 'input', width: '' },
  { label: '经营范围', code: 'businessScope', type: 'input', width: '' },
  {
    label: '调整前级别',
    code: 'adjustBeforeLevel',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.adjustBeforeLevel === '1' ? '一次' : data.adjustBeforeLevel === '2' ? '一般'
        : data.adjustBeforeLevel === '3' ? '合格' : '淘汰'
    }
  },
  {
    label: '调整后级别',
    code: 'adjustAfterLevel',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.adjustAfterLevel === '1' ? '一次' : data.adjustAfterLevel === '2' ? '一般'
        : data.adjustAfterLevel === '3' ? '合格' : '淘汰'
    }
  },
  {
    label: '调整类型',
    code: 'adjustType',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.adjustType === '1' ? '升级' : '降级'
    }
  },
  { label: '申请时间', code: 'applyDate', type: 'input', width: '120' },
  { label: '申请人', code: 'applyUserName', type: 'input', width: '' },
  { label: '变更时间', code: 'adjustDate', type: 'input', width: '120' }

]
export var tableData = []
